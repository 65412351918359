$(function() {

    // Activate Bootstrap tooltips.
    $('[data-toggle="tooltip"]').tooltip();

    // Activeate Bootstrap popovers.
    $('[data-toggle="popover"]').popover();

    // Loading button states.
    $('[data-loading-text]').on('click', function(e) {
        $(this).button('loading')
    });

    // Maintain active tab when using back/refresh buttons.
    if (location.hash.substr(0,2) == '?!') {
        $('a[href="?'+location.hash.substr(2)+'"]').tab('show');
    }

    $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
        var hash = $(e.target).attr('href');
        if (hash.substr(0,1) == '?') {
            location.replace('?!' + hash.substr(1));
        }
    });

    // prevent safari opening up links in a new browser window when in standalone mode (app)
    if(("standalone" in window.navigator) && window.navigator.standalone){
        var noddy, remotes = false;
        document.addEventListener('click', function(event) {
            noddy = event.target;

            while(noddy.nodeName !== "A" && noddy.nodeName !== "HTML") {
                noddy = noddy.parentNode;
            }
            if('href' in noddy && noddy.href.indexOf('http') !== -1 && (noddy.href.indexOf(document.location.host) !== -1 || remotes))
            {
                event.preventDefault();
                document.location.href = noddy.href;
            }
        }, false);
    }
 });
