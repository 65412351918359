Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

Vue.directive('date', {
    twoWay: true,
    bind: function () {
        var self = this;
        $(self.el).change(function() {
            var value = $(this).val();
            self.set(value);
        });
    },
    unbind: function () {
        var self = this;
    }
});

new Vue({

    el: '#users',
    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),

        filter: '',
        orderBy: 'name',
        sortBy: 'asc',

        view: 'index',

        // search model
        search: {
            keywords: '',
            filter: '',
            role_id: '',
            client_id: '',
        },
        form: {
            firstname: '',
            surname: '',
            email: '',
            role_id: '',
        },
        rolesList: [
            { text: 'Select', value: ''},
            { text: 'System Admin', value: 1},
            { text: 'Client User', value: 2},
            { text: 'Event Manager', value: 3},
        ],
        users: []
    },

    ready: function() {
        this.route();
        var self = this;
        window.onhashchange = function(e) {
            self.route();
        };
        //this.getSicCodeList();
    },

    methods: {
        route: function() {
            this.$set('loading.view', true);

            var hash = (window.location.hash.substr(1)).split('/');
            this.getClientList();

            // index
            if(! hash[0]) {
                this.view = 'index';
                this.getUsers();
                return;
            }

            // show
            if(hash.length === 1 && ! isNaN(hash[0])) {
                this.view = 'show';
                this.getUser(hash[0]);
                return;
            }

            // add
            if(hash.length === 1 && hash[0] == 'add') {
                this.loading.view = false;
                this.view = 'form';
                if(typeof this.errors !== 'undefined') {
                    this.errors.user = null;
                }
            }

            // edit
            if(hash.length === 2 && hash[1] == 'edit') {
                this.view = 'form';
                if(typeof this.errors !== 'undefined') {
                    this.errors.user = null;
                }
                if(this.users.length) {
                    var user = this.users.filter(function(user) {
                        return user.id == hash[0];
                    });

                    if(user.length) {
                        this.$set('form', user[0]);
                        this.$set('editing', JSON.parse(JSON.stringify(user[0])));
                    }

                    this.loading.view = false;
                }
                else {
                    this.$http.get(this.api_url+'/users/'+hash[0], function(response) {
                        this.loading.view = false;
                        this.$set('form', response.user);
                        this.$set('editing', JSON.parse(JSON.stringify(response.user)));
                    }).error(function (data, status, response) {
                        console.log(response);
                    });
                }
            }
        },

        // Get client list for select boxes
        getClientList: function() {
            this.$http.get(this.api_url+'/clients', function(response) {
                var clientList = [];
                clientList.push({ text: 'Select client', value: 0 });
                response.clients.forEach(function(client) {
                    clientList.push({
                        text: client.name,
                        value: client.id
                    });
                });
                this.$set('clientList', clientList);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Get users
        getUsers: function() {
            this.$http.get(this.api_url+'/users?per_page=10', function(response) {
                this.loading.view = false;
                this.$set('users', response.users);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Add user user
        addUser: function(e) {
            e.preventDefault();
            // validate
            var user = this.newUser;
            this.$http.post(this.api_url+'/users/user/'+this.user_id, user, function(response) {
                this.users.push(response.user);
                this.newUser = { firstname: '', surname: '', email: '', phone: '' }
                $('#adduserform').modal('hide');
            }).error(function (data, status, response) {
                if(status == 422) {
                     this.$set('errors.user', data.errors);
                }
            });
        },

        showUserForm: function() {
            this.newUser = {
                firstname: '',
                surname: '',
                email: '',
                phone: '',
                role_id: ''
            };
        },

        deleteUser: function(user) {
            var conf = window.confirm('Are you sure you want to delete this user?');

            if(conf) {
                this.$http.delete(this.api_url+'/users/'+user.id, function(response) {
                    this.users.$remove(user);
                }).error(function (data, status, response) {
                    console.log(response);
                });
            }
        },

        // Delete a user
        deleteUser: function(user) {
            var conf = window.confirm('Are you sure you want to delete this user?');

            if(conf) {
                this.$http.delete(this.api_url+'/users/'+user.id, function(response) {
                    this.users.$remove(user);
                }).error(function (data, status, response) {
                    console.log(response);
                });
            }
        },

        // register User
        submitForm: function(e) {
            e.preventDefault();

            if(typeof this.editing !== 'undefined') {

                this.$http.put(this.api_url+'/users/'+this.user.id, this.form, function(response) {
                    this.form = {
                        firstname: '',
                        surname: '',
                        email: '',
                        phone: '',
                        role_id: '',
                    };
                    this.getUsers();
                    this.loading.view = true;
                    this.view = 'index';
                    window.location = '#';
                }).error(function (data, status, response) {
                    if(status == 422) {
                         this.$set('errors.user', data.errors);
                    }
                });
                return;
            }

            this.$http.post(this.api_url+'/users', this.form, function(response) {
                this.form = {
                    firstname: '',
                    surname: '',
                    email: '',
                    phone: '',
                    role_id: '',
                };
                this.getUsers();
                this.loading.view = true;
                this.view = 'index';
                window.location = '#';
            }).error(function (data, status, response) {
                if(status == 422) {
                     this.$set('errors.user', data.errors);
                }
            });
        },

        // Pagination
        page: function(dir) {
            this.loading.view = true; // show loading spinner

            if(dir === 'prev' || dir === 'next') {
                var url = dir === 'prev' ? this.pagination.prev_page_url : this.pagination.next_page_url;
            } else {
                var url = dir;
            }

            url += '&orderBy=' + this.orderBy + '&sortBy=' + this.sortBy;
            if(parseInt(this.search.client_id)) url += '&client_id='+this.search.client_id;
            if(this.search.keywords) url += '&search='+this.search.keywords;
            if(this.search.role_id) url += '&role_id='+this.search.role_id;

            this.$http.get(url, function(response) {
                this.loading.view = false;
                this.$set('users', response.users);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Sort
        sort: function(orderBy) {
            this.loading.view = true; // show loading spinner

            url = this.api_url+'/users?per_page=10';

            url += '&orderBy=' + this.orderBy + '&sortBy=' + this.sortBy;
            if(parseInt(this.search.client_id)) url += '&client_id='+this.search.client_id;
            if(this.search.keywords) url += '&search='+this.search.keywords;
            if(this.search.role_id) url += '&role_id='+this.search.role_id;

            this.sortBy = (this.orderBy == orderBy) ? 'desc' : 'asc';
            this.orderBy = orderBy;

            url += '&orderBy=' + orderBy + '&sortBy=' + this.sortBy;

            this.$http.get(url, function(response) {
                this.loading.view = false;
                this.$set('users', response.users);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Search users
        searchUsers: function(e) {
            e.preventDefault();

            this.loading.users = true; // show loading spinner

            url = this.api_url+'/users?per_page=10';

            url += '&orderBy=' + this.orderBy + '&sortBy=' + this.sortBy;
            if(parseInt(this.search.client_id)) url += '&client_id='+this.search.client_id;
            if(this.search.keywords) url += '&search='+this.search.keywords;
            if(this.search.role_id) url += '&role_id='+this.search.role_id;

            this.$http.get(url, function(response) {
                this.loading.users = false; // remove loading spinner
                this.users = response.users;
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Clear search
        clearSearch: function() {
            this.loading.users = true; // show loading spinner

            this.$http.get(this.api_url+'/users?per_page=10', function(response) {
                this.loading.users = false; // remove loading spinner
                this.$set('users', response.users);
                this.search = {
                    client_id: 0,
                    role_id: 0,
                    keywords: ''
                };
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },
    }
});
