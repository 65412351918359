Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

Vue.directive('date', {
    twoWay: true,
    bind: function () {
        var self = this;
        $(self.el).change(function() {
            var value = $(this).val();
            self.set(value);
        });
    },
    unbind: function () {
        var self = this;
    }
});

new Vue({
    el: '#reports',

    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),

        search: {
            from: '',
            to: ''
        }
    },

    ready: function() {
        this.filterCharts();
        $.fn.datepicker.defaults.autoclose = true;
        $.fn.datepicker.defaults.daysOfWeekHighlighted = [0];
        $.fn.datepicker.defaults.startView = 'month';
        $.fn.datepicker.defaults.weekStart = 1;
    },

    methods: {

        filterCharts: function() {

            var ctx = document.getElementById('event-delegate-breakdown').getContext('2d');
            var url = this.api_url+'/reports/events/'+event.id+'/delegates/breakdown';

            if(this.search.from) url += '&from='+this.search.from;
            if(this.search.to) url += '&to='+this.search.to;

            this.$http.get(url, function(response) {

                if(this.typesChart) this.typesChart.destroy();
                $('#event-delegate-breakdown').parent().parent().children('ul.pie-legend').remove();

                var ctx = document.getElementById('timesheet-types').getContext('2d');

                var data = [];
                var colours = ['#46BFBD', '#FDB45C', '#F7464A'];

                var i = 0;
                response.data.forEach(function(set) {
                    data.push({
                        value: set.value,
                        highlight: '#555555',
                        color: colours[i],
                        label: set.label
                    });
                    i++;
                });

                var scanTypeBreakdown = new Chart(ctx).Pie(data, {
                    responsive: true,
                    maintainAspectRatio: true,
                    legendTemplate : '<ul class=\"<%=name.toLowerCase()%>-legend\">' +
                        '<% for (var i=0; i<segments.length; i++){%><li>' +
                            '<span style=\"background-color:<%=segments[i].fillColor%>\"></span>' +
                            '<%if(segments[i].label){%><%=segments[i].label%> <strong><%=segments[i].value%></strong><%}%>' +
                        '</li><%}%>' +
                    '</ul>'
                });

                $('#timesheet-types').parent().parent().append(scanTypeBreakdown.generateLegend());
                this.$set('typesChart', scanTypeBreakdown);

            });

        }
    }
});
