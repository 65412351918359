Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

Vue.directive('date', {
    twoWay: true,
    bind: function () {
        var self = this;
        $(self.el).change(function() {
            var value = $(this).val();
            self.set(value);
        });
    },
    unbind: function () {
        var self = this;
    }
});

new Vue({

    el: '#clients',
    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),

        filter: '',
        orderBy: 'name',
        sortBy: 'asc',

        view: 'index',

        // search model
        search: {
            keywords: ''
        },

        form: {
            sic_id: '',
            name: '',
            email: '',
            phone: '',
            addr_1: '',
            addr_2: '',
            addr_3: '',
            addr_city: '',
            addr_postcode: ''
        },

        newUser: {
            firstname: '',
            surname: '',
            email: '',
            role_id: 2,
            client_id: 0
        },

        clients: []
    },

    ready: function() {
        this.route();
        var self = this;
        window.onhashchange = function(e) {
            self.route();
        };
        //this.getSicCodeList();
    },

    methods: {
        route: function() {
            this.$set('loading.view', true);

            var hash = (window.location.hash.substr(1)).split('/');

            // index
            if(! hash[0]) {
                this.view = 'index';
                this.getClients();
                return;
            }

            // show
            if(hash.length === 1 && ! isNaN(hash[0])) {
                this.view = 'show';
                this.getClient(hash[0]);
                return;
            }

            // add
            if(hash.length === 1 && hash[0] == 'add') {
                this.loading.view = false;
                this.view = 'form';
                if(typeof this.errors !== 'undefined') {
                    this.errors.client = null;
                }
            }

            // edit
            if(hash.length === 2 && hash[1] == 'edit') {
                this.view = 'form';
                if(typeof this.errors !== 'undefined') {
                    this.errors.client = null;
                }
                if(this.clients.length) {
                    var client = this.clients.filter(function(client) {
                        return client.id == hash[0];
                    });

                    if(client.length) {
                        this.$set('form', client[0]);
                        this.$set('editing', JSON.parse(JSON.stringify(client[0])));
                    }

                    this.loading.view = false;
                }
                else {
                    this.$http.get(this.api_url+'/clients/'+hash[0], function(response) {
                        this.loading.view = false;
                        this.$set('form', response.client);
                        this.$set('editing', JSON.parse(JSON.stringify(response.client)));
                    }).error(function (data, status, response) {
                        console.log(response);
                    });
                }
            }
        },

        // Get list of sic codes
        /*
        getSicCodeList: function() {
            this.$http.get(this.api_url+'/sic', function(response) {
                var sicCodeList = [];
                sicCodeList.push({ text: 'Select category', value: 0 });
                response.codes.forEach(function(code) {
                    sicCodeList.push({
                        text: code.name + ' ('+code.code+')',
                        value: code.id
                    });
                });
                this.$set('sicCodeList', sicCodeList);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },*/

        // Add client user
        addUser: function(e) {
            e.preventDefault();
            // validate
            var user = this.newUser;
            this.$http.post(this.api_url+'/users/client/'+this.client.id, user, function(response) {
                this.users.push(response.user);
                this.newUser = { firstname: '', surname: '', email: '', phone: '' }
                $('#adduserform').modal('hide');
            }).error(function (data, status, response) {
                if(status == 422) {
                     this.$set('errors.user', data.errors);
                }
            });
        },

        showUserForm: function() {
            this.newUser = {
                firstname: '',
                surname: '',
                email: '',
                phone: '',
                role_id: 2,
                client_id: this.client.id
            };
        },

        deleteUser: function(user) {
            var conf = window.confirm('Are you sure you want to delete this client user?');

            if(conf) {
                this.$http.delete(this.api_url+'/users/'+user.id, function(response) {
                    this.users.$remove(user);
                }).error(function (data, status, response) {
                    console.log(response);
                });
            }
        },

        // Get contacts
        getContacts: function(id) {
            this.$http.get(this.api_url+'/contacts?client='+id+'&per_page=10', function(response) {
                this.$set('contacts', response.contacts);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Get clients
        getClients: function() {
            this.$http.get(this.api_url+'/clients?per_page=10', function(response) {
                this.loading.view = false;
                this.$set('clients', response.clients);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Get a client
        getClient: function(id) {

            // Get client info
            this.$http.get(this.api_url+'/clients/'+id, function(response) {
                this.loading.view = false;
                this.$set('client', response.client);
                this.getContacts(id);
            }).error(function (data, status, response) {
                console.log(response);
            });

            // Get events
            this.$http.get(this.api_url+'/events/?client_id='+id, function(response) {
                this.$set('events', response.events);
            }).error(function (data, status, response) {
                console.log(response);
            });

            // Get client users
            this.$http.get(this.api_url+'/users?client='+id, function(response) {
                this.$set('users', response.users);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Delete a client
        deleteClient: function(client) {
            var conf = window.confirm('Are you sure you want to delete this client?');

            if(conf) {
                this.$http.delete(this.api_url+'/clients/'+client.id, function(response) {
                    this.clients.$remove(client);
                }).error(function (data, status, response) {
                    console.log(response);
                });
            }
        },

        // Pagination
        page: function(dir) {
            this.loading.view = true; // show loading spinner

            if(dir === 'prev' || dir === 'next') {
                var url = dir === 'prev' ? this.pagination.prev_page_url : this.pagination.next_page_url;
            } else {
                var url = dir;
            }

            url += '&orderBy=' + this.orderBy + '&sortBy=' + this.sortBy;

            if(this.search.keywords) url += '&keywords='+this.search.keywords;

            this.$http.get(url, function(response) {
                this.loading.view = false;
                this.$set('clients', response.clients);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Sort
        sort: function(orderBy) {
            this.loading.view = true; // show loading spinner

            url = this.api_url+'/clients?per_page=10';

            if(this.search.keywords) url += '&keywords='+this.search.keywords;

            this.sortBy = (this.orderBy == orderBy) ? 'desc' : 'asc';
            this.orderBy = orderBy;

            url += '&orderBy=' + orderBy + '&sortBy=' + this.sortBy;

            this.$http.get(url, function(response) {
                this.loading.view = false;
                this.$set('clients', response.clients);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Search clients
        searchClients: function(e) {
            e.preventDefault();

            this.loading.clients = true; // show loading spinner

            var url = this.api_url+'/clients?per_page=10';

            if(this.search.keywords) url += '&keywords='+this.search.keywords;

            this.$http.get(url, function(response) {
                this.loading.clients = false; // remove loading spinner
                this.clients = response.clients;
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Clear search
        clearSearch: function() {
            this.loading.clients = true; // show loading spinner

            this.$http.get(this.api_url+'/clients?per_page=10', function(response) {
                this.loading.clients = false; // remove loading spinner
                this.$set('clients', response.clients);
                this.search = {
                    location_id: 0,
                    keywords: ''
                };
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        submitForm: function(e) {
            e.preventDefault();

            if(typeof this.editing !== 'undefined') {

                this.$http.put(this.api_url+'/clients/'+this.editing.id, this.form, function(response) {
                    this.form = {
                        sic_id: '',
                        name: '',
                        email: '',
                        phone: '',
                        addr_1: '',
                        addr_2: '',
                        addr_3: '',
                        addr_city: '',
                        addr_postcode: '',
                    };
                    this.getClients();
                    this.loading.view = true;
                    this.view = 'index';
                    window.location = '#';
                }).error(function (data, status, response) {
                    if(status == 422) {
                         this.$set('errors.client', data.errors);
                    }
                });
                return;
            }

            this.$http.post(this.api_url+'/clients', this.form, function(response) {
                this.form = {
                    sic_id: '',
                    name: '',
                    email: '',
                    phone: '',
                    addr_1: '',
                    addr_2: '',
                    addr_3: '',
                    addr_city: '',
                    addr_postcode: ''
                };
                this.getClients();
                this.loading.view = true;
                this.view = 'index';
                window.location = '#';
            }).error(function (data, status, response) {
                if(status == 422) {
                     this.$set('errors.client', data.errors);
                }
            });
        }
    }
});
